/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import React from 'react';
import nProgress from 'nprogress';
import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { DialogClose } from '@radix-ui/react-dialog';
import Link from 'next/link';
import { DocumentModel, Order, Shipment } from '../../../../../models';
import { FormHeader } from '../../../../forms';
import { DocumentForm } from '../../../../document';
import { ACTIONS, ShipmentActions } from './shippingReducer';
import {
  CREATE_ORDER,
  CREATE_SHIPMENT,
  NEW_SHIPMENT_EMAIL,
  UPDATE_ORDER,
} from '../../../../../graphql/mutation';
import { useToast } from '../../../../../hooks/useToast';
import { Button } from '../../../../ui/button';
import {
  getShippingRates,
  receptionPlaces,
} from '../../../../../utils/calculatorInfo';
import { useUser } from '../../../../../hooks';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../../../ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../ui/accordion';
import { GET_RECEPTION_REGIONS } from '../../../../../graphql/queries';

interface AddressProps {
  state: Partial<Shipment>;
  dispatch: React.Dispatch<ShipmentActions>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  updateURLs: (urls: DocumentModel[]) => void;
  images: DocumentModel[];
  updatePackingLists: (urls: DocumentModel[]) => void;
  packingLists: DocumentModel[];
  updateInvoices: (urls: DocumentModel[]) => void;
  invoices: DocumentModel[];
  update?: boolean;
  order?: Order;
}

function References({
  state,
  dispatch,
  setSelected,
  updateURLs,
  images,
  updatePackingLists,
  packingLists,
  updateInvoices,
  invoices,
  update = false,
  order,
}: AddressProps) {
  const router = useRouter();
  const [user] = useUser();
  const [newShipmentEmail] = useMutation(NEW_SHIPMENT_EMAIL);
  const { invoice, boxesPictures, packingList } = state;

  const [updateOrder] = useMutation(UPDATE_ORDER);
  const { data, loading } = useQuery(GET_RECEPTION_REGIONS, {});
  const [createOrder] = useMutation(CREATE_ORDER);
  const [disabled, setDisabled] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);
  const { toast } = useToast();

  function getRateByCbm(cbm, rates) {
    for (let i = 0; i < rates.length; i++) {
      const { minCbm, maxCbm, rate } = rates[i];
      if (cbm >= minCbm && cbm <= maxCbm) {
        return rate;
      }
    }
    return null; // if cbm is outside the defined range, return null or throw an error
  }

  function calculateShippingCost(
    _receptionPlace: string,
    _shippingType: string
  ) {
    let totalCost = 0;
    // Si el envío es por aire
    if (_shippingType === 'air') {
      totalCost = 25 * parseFloat(state.cbm);
      return totalCost.toFixed(2);
    }

    // Obtener las tarifas de envío según el lugar de recepción y el tipo de envío

    const _region = receptionPlaces.find(
      (place) => place.value === _receptionPlace
    );
    const shippingRates = getShippingRates(
      data?.receptionRegions,
      _region?.receptioRegion,
      _shippingType
    );
    // Encontrar la tarifa que se aplica al peso facturable
    const actualRate = getRateByCbm(parseFloat(state.cbm), shippingRates);

    // Calcular el costo total de envío
    totalCost = actualRate * parseFloat(state.cbm);

    return totalCost.toFixed(2);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    nProgress.start();
    setDisabled(true);

    // Inicializa una lista para almacenar mensajes de error
    const errors = [];

    // Validación: CBM debe ser un número y no vacío
    if (!Number(state.cbm) || state.cbm === '') {
      errors.push('CBM debe ser un número válido y no puede estar vacío.');
    }

    // Validaciones: campos requeridos no deben estar vacíos
    if (!state.address || state.address.trim() === '') {
      errors.push('La dirección no puede estar vacía.');
    }

    // Fotos de las cajas, Packing List e Invoice no pueden estar vacíos
    if (!images || images.length === 0) {
      errors.push('Debe incluir al menos una foto de las cajas.');
    }

    if (!packingLists || packingLists.length === 0) {
      errors.push('El Packing List no puede estar vacío.');
    }

    if (!invoices || invoices.length === 0) {
      errors.push('La Factura no puede estar vacía.');
    }

    // merchType debe ser texto y no vacío
    if (!state.merchType || state.merchType.trim() === '') {
      errors.push('El tipo de mercancía no puede estar vacío.');
    }

    // boxesNumber debe ser un número y no vacío
    if (!Number(state.boxesNumber) || state.boxesNumber === '') {
      errors.push(
        'El número de cajas debe ser un número válido y no puede estar vacío.'
      );
    }

    // trackingNumber debe ser texto. Puede estar vacío pero si no lo está, debe ser un texto válido en una sola cadena de caracteres sin estar separados por espacios
    if (state.trackingNumber && state.trackingNumber.includes(' ')) {
      errors.push(
        'El número de tracking no puede contener espacios en blanco.'
      );
    }

    // Muestra los mensajes de error si hay errores y detiene la ejecución
    if (errors.length > 0) {
      toast({
        variant: 'destructive',
        title: 'Error en la validación',
        description: errors.join('\n'), // Concatena todos los mensajes de error
      });
      nProgress.done();
      setDisabled(false);
      return; // Detiene la ejecución de la función aquí
    }

    // Si pasa todas las validaciones, procede con la operación
    try {
      if (!update) {
        const { data: orderData } = await createOrder({
          variables: {
            data: {
              fistLine: state.address,
              dangerous: state.dangerous,
              replica: state.replica,
              postalCode: state.postalCode,
              merchType: state.merchType,
              boxesNumber: state.boxesNumber,
              boxesPictures: images.map((image) => image.src) ?? [],
              packingList: packingLists.map((packing) => packing.src) ?? [],
              invoice: invoices.map((inv) => inv.src) ?? [],
              cbm: state.cbm,
              trackingNumber:
                state.trackingNumber === ''
                  ? 'Sin número de tracking'
                  : state.trackingNumber,
              shippingCost: calculateShippingCost(
                state.receptionState,
                state.shippingType
              ),
              user: user._id,
              status: 'pending',
              modality: 1,
              approved: 'WAITING_FOR_APPROVAL',
              shippingType: state.shippingType,
              title: 'Envío creado',
              movementType: 'na',
            },
          },
        });

        if (orderData) {
          toast({
            variant: 'success',
            title: 'Éxito',
            description: 'Tu envío ha sido guardado exitosamente.',
          });
          router.push('/app/orders');
        }
      } else {
        const { data: orderData } = await updateOrder({
          variables: {
            data: {
              orderId: order._id,
              title: 'Envío actualizado por el cliente',
              fistLine: state.address,
              dangerous: state.dangerous,
              replica: state.replica,
              postalCode: state.postalCode,
              merchType: state.merchType,
              boxesNumber: state.boxesNumber,
              boxesPictures: images.map((image) => image.src) ?? [],
              packingList: packingLists.map((packing) => packing.src) ?? [],
              invoice: invoices.map((inv) => inv.src) ?? [],
              cbm: state.cbm,
              trackingNumber:
                state.trackingNumber === ''
                  ? 'Sin número de tracking'
                  : state.trackingNumber,
              shippingCost: calculateShippingCost(
                state.receptionState,
                state.shippingType
              ),
              user: user._id,
              status: 'pending',
              modality: 1,
              approved: order?.approved,
              shippingType: state.shippingType,
              movementType: 'na',
            },
          },
        });
        // radix ui dialog close
        toast({
          variant: 'success',
          title: 'Éxito',
          description: 'Tu envío ha sido actualizado exitosamente.',
        });
      }
    } catch (err) {
      console.error('Error al crear el envío:', err);
      // Asumiendo que err es un objeto de error que puede contener un mensaje detallado
      const errorMessage =
        err.message || 'Ocurrió un error desconocido al guardar tu envío.';
      toast({
        variant: 'destructive',
        title: 'Error al crear el envío',
        description: errorMessage,
      });
    } finally {
      nProgress.done();
      setDisabled(false);
    }
  };

  return (
    <div className="flex flex-col">
      <Accordion type="single" collapsible className="w-full px-4 pb-2">
        <AccordionItem value="boxes">
          <AccordionTrigger>
            Agrega fotos de tus cajas identificadas
          </AccordionTrigger>
          <AccordionContent>
            <div className="w-full px-10 py-8">
              <DocumentForm
                updateURLs={updateURLs}
                documents={images}
                maximumAmount={5}
                fileFormats="images"
              />
            </div>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="packing-list">
          <AccordionTrigger>
            Agrega tu packing list en formato Excel o PDF
          </AccordionTrigger>
          <AccordionContent>
            <div className="w-full px-10 py-8">
              <DocumentForm
                updateURLs={updatePackingLists}
                documents={packingLists}
                maximumAmount={5}
              />
            </div>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
          <AccordionTrigger>
            Adjuntar Factura de la mercancía (PDF o Excel)
          </AccordionTrigger>
          <AccordionContent>
            <div className="w-full px-10 py-8">
              <DocumentForm
                updateURLs={updateInvoices}
                documents={invoices}
                maximumAmount={5}
              />
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Button
        className={`${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } w-full`}
        onClick={onSubmit}
        disabled={disabled}
        // type="submit"
      >
        {
          // eslint-disable-next-line no-nested-ternary
          update
            ? order?.approved === 'WAITING_FOR_APPROVAL'
              ? 'Actualizar'
              : 'Guardar'
            : 'Hacer envío'
        }
      </Button>
      {/* Callout saying that if client does not have the things please send an email */}
      <Card className="w-full mt-2">
        <CardHeader>
          <CardTitle>¿No tienes los documentos a la mano?</CardTitle>
        </CardHeader>
        <CardContent>
          <CardDescription>
            Si no tienes los documentos a la mano, no te preocupes, puedes
            solicitarlos a tu proveedor
          </CardDescription>
        </CardContent>
        <CardFooter>
          <Link
            href="https://mogos.nyc3.digitaloceanspaces.com/Te%CC%81rminos%20y%20condiciones%20despacho%20de%20mercanci%CC%81a%20MOGOS%20GROUP,%20C.A..pdf"
            rel="noopener noreferrer"
            target="_blank"
            className=" text-start justify-start items-start text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Términos y condiciones
          </Link>
        </CardFooter>
      </Card>
      <div className="flex items-center space-x-2" />
    </div>
  );
}

export default References;
